@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-pagination-bullet-active {
  @apply !bg-white;
}

.grecaptcha-badge {
  display: none !important;
}
